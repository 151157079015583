import { useAppDispatch } from "../../../../hooks/redux.hook";
import {
  setCurrentSectionPosts,
  updateSectionPinnedPost,
} from "../../../../_store/sections.store";
import I18 from "../../../atoms/i18";
import { CompactPostCardList } from "../../../post/compact-post-card-list.component";
import GridPostsList from "../../../post/grid-post-list-component";
import { PostCardList } from "../../../post/post-card-list.component";
import PostListShimmer, {
  PostTileType,
} from "../../../shimmers/post/post-list-shimmer.component";

/**
 * @description - Display list of posts in section detail page
 * @param {any} user - user object
 * @param {any} activeTabModel - active section object
 * @param {Array<any>} posts - list of posts in section
 * @param {Array<any>} pinnedPosts - list of pinned posts in section
 * @param {boolean} isLoadingPosts - Flag to check if posts are loading
 * @param {boolean} noMorePosts - Flag to check if there are more posts left to load
 * @param {boolean} isLoadingMorePosts - Flag to check if more posts are loading
 */
export default function SectionPostList({
  user,
  activeTabModel,
  posts,
  pinnedPosts,
  noMorePosts = false,
  isLoadingPosts = false,
  isLoadingMorePosts = false,
}) {
  const dispatch = useAppDispatch();
  if (activeTabModel.sectionView === "grid") {
    return (
      <>
        <GridPostsList
          user={user}
          isLoading={isLoadingPosts}
          allPosts={posts}
          posts={posts}
        />
        <>
          {isLoadingMorePosts ? (
            <div className="PostList my-2 grid grid-cols-1 gap-4 rounded p-2 px-2 sm:grid-cols-2 sm:px-0">
              {Array.from({ length: 6 }).map((_, i) => (
                <PostListShimmer
                  key={i}
                  length={1}
                  type={PostTileType.compact}
                />
              ))}
            </div>
          ) : (
            <div className="h-96"></div>
          )}
        </>
      </>
    );
  }

  if (activeTabModel.sectionView === "compact") {
    return (
      <CompactPostCardList
        posts={posts}
        allPosts={posts}
        onPostUpdate={(post, list) => {
          if (post.isPinned) {
            dispatch(updateSectionPinnedPost(post));
          }
          if (list) {
            dispatch(setCurrentSectionPosts(list));
          }
        }}
      />
    );
  }
  return (
    <>
      <div>
        {/* pinned posts */}
        <PostCardList
          posts={pinnedPosts}
          allPosts={posts}
          onPostUpdate={(post, list) => {
            if (post.isPinned) {
              dispatch(updateSectionPinnedPost(post));
            }
            if (list) {
              dispatch(setCurrentSectionPosts(list));
            }
          }}
        />
        {/* normal posts */}
        <PostCardList
          posts={posts.filter((p) => !p.isPinned)}
          allPosts={posts}
          isLoading={isLoadingPosts}
          placeholder={
            <PostListShimmer length={6} type={PostTileType.comfortable} />
          }
          footerElement={
            <>
              {isLoadingMorePosts && (
                <div className="PostList bg-card my-2 rounded p-2">
                  <PostListShimmer length={3} type={PostTileType.compact} />
                </div>
              )}
              {noMorePosts && (
                <div className="text-secondary-foreground/80 flex h-32 place-content-center items-center text-xs">
                  <I18>
                    Looks like you have reached the end of the list, No more
                    post to display
                  </I18>
                </div>
              )}
            </>
          }
          onPostUpdate={(post, list) => {
            if (post.isPinned) {
              dispatch(updateSectionPinnedPost(post));
            }
            if (list) {
              dispatch(setCurrentSectionPosts(list));
            }
          }}
        />
      </div>
    </>
  );
}
