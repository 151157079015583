import cx from "classnames";
import { format, isAfter, isBefore } from "date-fns";
import React, { useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Remarkable } from "remarkable";
import { ToastTypes, withToast } from "../contexts/toastr.context";
import { useAppService } from "../hooks/use-app-service";
import useLang from "../hooks/use-lang.hook";
import { Button } from "../_components";
import { ActionEventModal } from "../_components/action-event-modal.component";
import { ActionModal } from "../_components/action-modal.component";
import I18 from "../_components/atoms/i18";
import { Avatar } from "../_components/avatar.component";
import { EventCreateModel } from "../_components/event/create-event-modal-component";
import SidebarEventDetailsCard from "../_components/event/sidebar-event-details-card.component";
import IconMenu2 from "../_components/icon-menu-2.component";
import EventDetailShimmer from "../_components/shimmers/event-detail-shimmer.component";
import UIcon from "../_components/uicon-component";
import { history } from "../_config";
import { EventService } from "../_service/event.service";
import { createMeetingLinkWithToken } from "./meeting.page";

export const EVENTS_DETAIL_PAGE_ROUTE = "/event/:id";
export const EVENTS_DETAIL_SLUG_PAGE_ROUTE = "/e/:slug";
export const createEventDetailPageRoute = (eventId) => "/event/" + eventId;
export function createEventDetailSlugPageRoute(event) {
  if (
    event.meta != undefined &&
    event.meta != null &&
    event.meta.slug != null
  ) {
    return "/e/" + event.meta.slug;
  } else {
    return createEventDetailPageRoute(event.id);
  }
}
export function createPostDetailPageUrl(event) {
  return window.location.origin + createEventDetailSlugPageRoute(event);
}

function EventDetailPageComponent({ user, community, addToast }) {
  const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteEvent, setIsDeleteEvent] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [event, setEvent] = useState();
  const { id, slug } = useParams();
  const [activeModel, setActiveModel] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isShowAttendee, setIsShowAttendee] = useState(false);
  const [isShowInvite, setIsShowInvite] = useState(false);
  const [notifyUser, setNotifyUser] = useState(false);
  const [isNotifyModalVisible, setIsNotifyModalVisible] = useState(false);
  const [isDeleteAllRecurringEvents, setIsDeleteAllRecurringEvents] =
    useState(false);

  const { analyticsService } = useAppService();

  const lang = useLang();

  React.useEffect(() => {
    setIsLoading(true);
    getEventDetails();
  }, [id, slug]);

  React.useEffect(() => {
    if (notifyUser) {
      onSubmitDelete();
    }
  }, [notifyUser]);

  const getEventDetails = () => {
    if (id) {
      EventService.getEventDetail(user, community.id, id)
        .then((response) => {
          setEvent(response.event);
          setIsLoading(false);
        })
        .catch((error) => {
          addToast("Events could not be fetched!", "", ToastTypes.danger);
          setIsLoading(false);
        });
    } else if (slug) {
      EventService.getEventDetailBySlug(user, community.id, slug)
        .then((response) => {
          setEvent(response.event);
          setIsLoading(false);
        })
        .catch((error) => {
          addToast("Events could not be fetched!", "", ToastTypes.danger);
          setIsLoading(false);
        });
    }
  };

  const onSubmitDelete = () => {
    setIsDeleteEvent(true);
    setIsDeleteModalVisible(true);
    EventService.deleteEvent(
      user,
      community.id,
      event.id,
      notifyUser,
      isDeleteAllRecurringEvents
    )
      .then((response) => {
        // delete event
        analyticsService.track("event-delete");
        addToast("Event deleted!");
        history.push("/events");
      })
      .catch((err) => {
        console.log(err);
        setIsDeleteModalVisible(true);
        addToast("Event could not be deleted!", "", "danger");
      })
      .finally(() => {
        setIsDeleteEvent(false);
        setIsDeleteModalVisible(false);
      });
  };

  const onAttendeeClick = () => {
    setIsShowAttendee(true);
    setActiveModel(true);
  };

  const onInviteClick = () => {
    setIsShowInvite(true);
    setActiveModel(true);
  };

  // should notifyUser
  const onSubmitNotify = () => {
    setNotifyUser(true);
    setIsNotifyModalVisible(false);
  };

  // dont notifyUser
  const onCancelNotify = () => {
    setIsNotifyModalVisible(false);
    onSubmitDelete();
  };

  if (event === undefined || event === null) {
    return (
      <div className="loading flex-col items-center justify-center">
        <EventDetailShimmer />
      </div>
    );
  }

  const isMeetingScheduled = event.startTime;
  const isScheduleMeetingStarted = isMeetingScheduled
    ? isBefore(new Date(event.startTime), Date.now())
    : false;
  const isScheduleMeetingEnded = isMeetingScheduled
    ? isAfter(Date.now(), new Date(event.endTime))
    : false;

  const joinScheduledMeeting = (e) => {
    e.preventDefault();
    if (user) {
      if (!isScheduleMeetingEnded) {
        window.open(
          createMeetingLinkWithToken(event.liveMeeting.meetingId, user.token)
        );
        analyticsService.track("join-event-button-clicked", {
          eventURL: event.liveMeeting.meetingId,
        });
      } else if (isScheduleMeetingEnded) {
        addToast("Event over", "", ToastTypes.info);
      }
    }
  };
  const joinScheduledPersonalMeeting = (e) => {
    e.preventDefault();
    analyticsService.track("join-event-button-clicked", {
      eventURL: event.webURL,
    });
    window.open(event.webURL);
    // open the meeting page with user token
    // if (user) {
    //   // check if scheduled time is passed
    //   if (isScheduleMeetingStarted) {
    //     window.open(event.webURL);
    //   } else {
    //     addToast(
    //       "You can join the event after scheduled time",
    //       "",
    //       ToastTypes.info
    //     );
    //   }
    // }
  };
  return (
    <>
      <div className="max-w-screen lg:max-w-auto min-h-screen lg:min-w-[670px]">
        {event ? (
          <div className="mb-2 flex w-full items-center justify-between border-b border-border bg-card px-4 py-3 lg:w-[calc(100vw-300px)]">
            <div
              className="flex cursor-pointer gap-2 text-sm font-semibold text-secondary-foreground"
              onClick={() => history.push("/events")}>
              <UIcon icon="arrow-small-left" />
              <I18>Back to Events</I18>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="mx-auto flex max-w-[670px] flex-col space-y-4">
          {/* EVENT */}
          <div className="w-full border border-border bg-card md:rounded-lg">
            {event.banner && (
              <div className="theme-bg-disable eventBannerImage relative flex max-h-[270px] place-content-center rounded">
                <div className="absolute left-0 top-0 max-h-[270px] w-full rounded-t">
                  <img
                    src={event.banner}
                    className="absolute left-0 top-0 max-h-[270px] w-full rounded-t object-cover"
                    alt=""
                    // style={{maxWidth:"100%", maxHeight:"56.49%", height:'auto',width:'auto'}}
                  />
                </div>
              </div>
            )}

            <div className="mx-4 rounded-xl p-7 md:mx-0">
              <div className="flex flex-row justify-between">
                <p className="my-2 text-lg font-semibold text-secondary-foreground">
                  {event.title}
                </p>
                <div className="flex">
                  {user &&
                    event.myCommunityRole === "admin" &&
                    isAfter(new Date(event.startTime), Date.now()) && (
                      <>
                        <div
                          onClick={() => onInviteClick()}
                          className="mr-2 mt-2.5 cursor-pointer text-sm font-normal text-secondary-foreground">
                          <I18>Share</I18>
                        </div>
                        <IconMenu2
                          icon="menu-dots-vertical"
                          actions={[
                            {
                              icon: "edit",
                              label: "Edit",
                              onClick: (e) => {
                                setActiveModel(true);
                              },
                            },
                            {
                              icon: "trash",
                              label: "Delete",
                              labelClass: "text-alert",
                              onClick: () => {
                                setIsDeleteModalVisible(true);
                              },
                              actionType: "alert",
                            },
                            {
                              icon: "trash",
                              label: "Delete Subsequent Events",
                              labelClass: "text-alert",
                              onClick: () => {
                                setIsDeleteAllRecurringEvents(true);
                                setIsDeleteModalVisible(true);
                              },
                              actionType: "alert",
                            },
                          ]}
                        />
                      </>
                    )}
                  {user &&
                    event.myCommunityRole === "admin" &&
                    isAfter(Date.now(), new Date(event.endTime)) && (
                      <>
                        <IconMenu2
                          icon="menu-dots"
                          actions={[
                            {
                              icon: "trash",
                              label: "Delete",
                              labelClass: "text-alert",
                              onClick: () => {
                                setIsDeleteModalVisible(true);
                              },
                              actionType: "alert",
                            },
                            {
                              icon: "trash",
                              label: "Delete Subsequent Events",
                              labelClass: "text-alert",
                              onClick: () => {
                                setIsDeleteAllRecurringEvents(true);
                                setIsDeleteModalVisible(true);
                              },
                              actionType: "alert",
                            },
                          ]}
                        />
                      </>
                    )}
                </div>
              </div>

              <div className="mt-2.5 flex items-center">
                <Avatar user={event.host} noName={true} />
                <span className="text-xs font-semibold text-secondary-foreground">
                  Hosted By {event?.host?.name}
                </span>
              </div>
              {user &&
              (event.myRSVPStatus === "going" ||
                user.id === event.host.id ||
                event.myCommunityRole === "admin") ? (
                <div className="mt-2.5">
                  {event.liveMeeting !== null ? (
                    <Button
                      onClick={joinScheduledMeeting}
                      className="mr-2"
                      label="Join Event"
                      large
                    />
                  ) : null}
                  {event.webURL && event.liveMeeting === null ? (
                    <Button
                      disabled={new Date(event.startTime) > new Date()}
                      onClick={joinScheduledPersonalMeeting}
                      className="mr-2"
                      label="Join Event"
                      large
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {event.description && (
          <div className="mx-auto mb-8 mt-2.5 flex max-w-[670px] flex-col">
            <div className="w-full border border-border bg-card p-7 text-secondary-foreground md:rounded-lg">
              <p className="text-xs font-semibold">DETAILS</p>
              {event.description && (
                <EventDescription event={event} user={user} />
              )}
            </div>
          </div>
        )}

        <EventCreateModel
          active={activeModel}
          user={user}
          community={community}
          eventToUpdate={event}
          onUpdateEvent={(event) => {
            setEvent(event);
            history.push(createEventDetailSlugPageRoute(event));
          }}
          onNewEventAdded={(event) => {}}
          isShowAttendee={isShowAttendee}
          setIsShowAttendee={setIsShowAttendee}
          isShowInvite={isShowInvite}
          setIsShowInvite={setIsShowInvite}
          openModel={(val) => {
            setActiveModel(val);
          }}
        />
        <ActionModal
          active={isDeleteModalVisible}
          setActive={setIsDeleteModalVisible}
          onSubmit={() => {
            if (isAfter(Date.now(), new Date(event.endTime))) {
              onCancelNotify();
            } else {
              setIsDeleteModalVisible(false);
              setIsNotifyModalVisible(true);
            }
          }}
          header={"Delete Event"}
          title={"Are you sure you want to delete the event?"}
          isLoading={isDeleteEvent}
          btnColor="red"
        />
        <ActionEventModal
          active={isNotifyModalVisible}
          setActive={setIsNotifyModalVisible}
          onSubmit={onSubmitNotify}
          onCancelSubmit={onCancelNotify}
          labelCancel="No, its okay"
          labelSubmit="Yes, Notify them"
          title={"Do you wish to notify that event is cancelled?"}
          subTitle={`${
            event.participantCount > 0
              ? `${event.participantCount} ${lang.trans(
                  "users wants to attend this event"
                )}`
              : ""
          } `}
          isLoading={isLoading}
          large
          btnColor="theme-bg-disabled"
        />
      </div>

      <style jsx>{`
        @media (min-width: 990px) and (max-width: 1200px) {
          .margin-left-300 {
            margin-left: 300px;
            min-width: 670px;
          }
        }
      `}</style>
      <div
        className={cx("mt-0 mt-2.5 flex lg:mr-8 lg:mt-14", "margin-left-300")}>
        <SidebarEventDetailsCard
          event={event}
          addToast={addToast}
          user={user}
          joinScheduledMeeting={joinScheduledMeeting}
          joinScheduledPersonalMeeting={joinScheduledPersonalMeeting}
          onAttendeeClick={onAttendeeClick}
          getEventDetails={getEventDetails}
        />
      </div>
    </>
  );
}

function getVideoEmbedFromDescription(description = "") {
  // check for youtube video // for link
  let newDescription = description.replace(
    /(<a href=")(?:(?:http:|https:)(?:\/\/))?(?:www\.)?(?:youtube.com|youtu.be)\/(?:watch)?(?:\?v=)?([^&<"\>\(\)\[\] \n]+)([^"]+)(">)([^<]+)(<\/a>)/g,
    '<p><iframe title="Youtube Embed" src="https://www.youtube.com/embed/$2" width="100%" height="315" className="my-2" allowFullScreen></iframe></p>'
  );
  // for text link
  newDescription = description.replace(
    /(?:(?:http:|https:)(?:\/\/))?(?:www\.)?(?:youtube.com|youtu.be)\/(?:watch)?(?:\?v=)?([^&<"\>\(\)\[\] \n]+)([^" \n]+)/g,
    '<p><iframe title="Youtube Embed" src="https://www.youtube.com/embed/$1" width="100%" height="315" className="my-2" allowFullScreen></iframe></p>'
  );
  // check for vimeo video
  newDescription = newDescription.replace(
    /(?:[^"'https:\/\/])(?:(?:http:|https:)(?:\/\/))?(?:www\.)?(?:player\.)?(?:vimeo.com)\/(?:video\/)?([^<"\>\(\)\[\] \n]+)/g,
    '<p><iframe title="Vimeo Embed" src="https://player.vimeo.com/video/$1" width="100%" height="360" className="my-2" frameBorder="0" allow="fullscreen" allowFullScreen></iframe></p>'
  );

  return newDescription;
}
/**
 * Event description
 * @param {*} param0
 * @returns
 */
function EventDescription({ event, user }) {
  const [fullView, setFullView] = useState(false);

  let { description } = event;

  if (!description) return <></>;

  const md = new Remarkable();

  const renderedDescription = md.render(description);

  let descriptionWithMentions = renderedDescription;
  try {
    descriptionWithMentions = renderedDescription.replace(
      /\B\@([\w\-]+)/gim,
      (match) => {
        return `<span class="text-primary cursor-pointer bg-primary-foreground">${match}</span>`;
      }
    );
  } catch (error) {}

  const descriptionWithEmbeds = getVideoEmbedFromDescription(
    descriptionWithMentions
  );

  return (
    <div className={cx("my-4 break-words")}>
      <div
        className="NL2BR headings"
        dangerouslySetInnerHTML={{ __html: descriptionWithEmbeds }}></div>
      {/* <PostYoutubeEmbedFromDescription description={post.description} /> */}
      {/* <PostVimeoEmbedFromDescription description={post.description} /> */}
    </div>
  );
}
/**
 * Event Host
 * @param {*} param0
 * @returns
 */

const EventDetailPage = withToast(
  connect((s) => ({ user: s.auth, community: s.community }))(
    EventDetailPageComponent
  )
);

// Return event start and end time
// if start time is same as end time then return only start time
export function formatEventTime(start, end) {
  const startTime = new Date(start);
  const endTime = new Date(end);
  if (startTime.getTime() === endTime.getTime()) {
    return format(startTime, "dd MMMM, yyyy, p");
  } else if (
    startTime.getFullYear() === endTime.getFullYear() &&
    startTime.getMonth() === endTime.getMonth() &&
    startTime.getDate() === endTime.getDate()
  ) {
    /// When Event is on same date
    return format(startTime, "dd MMMM, yyyy, p");
  } else if (
    startTime.getFullYear() === endTime.getFullYear() &&
    startTime.getMonth() === endTime.getMonth()
  ) {
    /// When Event is in same month
    return `${format(startTime, "dd MMM, p")} - ${format(
      endTime,
      "dd MMM, yyyy, p"
    )}`;
  } else if (startTime.getFullYear() === endTime.getFullYear()) {
    /// When Event is in same year
    return `${format(startTime, "dd MMM,  p")} - ${format(
      endTime,
      "dd MMM, yyyy, p"
    )}`;
  } else {
    /// When Event is in different year
    return `${format(startTime, "dd MMM, yyyy, p")} - ${format(
      endTime,
      "dd MMM, yyyy, p"
    )}`;
  }
}

export default EventDetailPage;

<style></style>;
