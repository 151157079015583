import cx from "classnames";
import { ReactNode, useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/redux.hook";
import { useAppService } from "../../hooks/use-app-service";
import { useToast } from "../../hooks/use-toast.hook";
import { CommunityTopic } from "../../types/community-topic/community-topic";
import { Community } from "../../types/community/community.type";
import { Post } from "../../types/post/post.type";
import { User } from "../../types/user/minimal-user.type";
import { Widget } from "../../types/widget/widget.type";
import { PostCard } from "../../_components";
import I18 from "../../_components/atoms/i18";
import { Avatar } from "../../_components/avatar.component";
import FeaturedPosts from "../../_components/community/featured-posts.component";
import Filter from "../../_components/filter.component";
import SelectWidgetModalV2 from "../../_components/group/select-widget-modal-v2.component";
import { MinimizedPostCard } from "../../_components/minimised-post-card";
import { ListView } from "../../_components/molecule/listview.component";
import CreatePost from "../../_components/post/create";
import RightSideModal from "../../_components/right-side-modal.component";
import { SelectTopicModal } from "../../_components/post/create/component";
import PostListShimmer, {
  PostTileType,
} from "../../_components/shimmers/post/post-list-shimmer.component";
import UIcon from "../../_components/uicon-component";
import CustomWidget from "../../_components/widgets/custom-widget.components";
import LeaderBoardWidget from "../../_components/widgets/leaderboard-widget-component";
import MemberWidget from "../../_components/widgets/member-widget";
import TrendingPostWidget from "../../_components/widgets/trending-post-widget.component";
import UpcomingEventWidget from "../../_components/widgets/upcoming-events.widget.component";
import Validator from "../../_utils/validator";

interface HomePagePostListProps {
  community: Community;
  posts: Post[];
  setPosts: (posts: Post[]) => void;
  user: User;
  widgets: Widget[];
  hasMorePost: boolean;
  onWidgetListUpdate: (widgets: Widget[]) => void;
  isLoadingPosts: boolean;
  isLoadingMorePosts: boolean;
  sortOrder: string;
  setSortOrder: (sortOrder: string) => void;
}

export default function HomePagePostList({
  community,
  posts = [],
  setPosts,
  user,
  widgets,
  hasMorePost,
  onWidgetListUpdate = (e) => {},
  isLoadingPosts = false,
  isLoadingMorePosts = false,
  sortOrder = "recent",
  setSortOrder = (e) => {},
}: HomePagePostListProps) {
  const [isSelectWidgetVisible, setIsSelectWidgetVisible] = useState(false);

  const hasPrimaryPermission = ["admin", "moderator"].includes(
    community?.myRole ?? ""
  );

  const { analyticsService } = useAppService();
  const { addToast } = useToast();

  const { topics } = useAppSelector((state) => {
    return {
      topics: state.communityTopics.topics,
    };
  });

  const [isTopicModalOpen, setIsTopicModalOpen] = useState(false);
  const [selectedTopicFilter, setSelectedTopicFilter] = useState<{
    key: string;
    label: string;
    icon: string;
    bgColor: string;
    textColor: string;
    isCommunityTopic: boolean;
  } | null>(null);

  const [filterOptions, setFilterOptions] = useState<any>([
    { label: "Recent", key: "recent", icon: "clock" },
    {
      label: "Top Posts",
      key: "top",
      icon: "arrow-from-bottom",
    },
    { label: "Trending", key: "trending", icon: "trophy" },
    { label: "Following", key: "following", icon: "following" },
  ]);

  const topicOptions = Array.isArray(topics)
    ? topics?.filter(
        (topic: CommunityTopic) =>
          topic.postCount > 0 && topic.status === "ACTIVE"
      )
    : [];

  const removeSelectedCommunityTopicFilter = () => {
    if (selectedTopicFilter) {
      const updatedOptions = filterOptions.filter(
        (option: {
          key: string;
          label: string;
          icon: string;
          bgColor: string;
          textColor: string;
          isCommunityTopic: boolean;
        }) => option.key !== selectedTopicFilter.key
      );
      setFilterOptions(updatedOptions);
    }
    setSortOrder(filterOptions[0].key);
    setSelectedTopicFilter(null);
  };

  useEffect(() => {
  }, [sortOrder]);

  const clickPostInputBox = () => {
    analyticsService.track("create-post-input-box-on-homefeed" as any);
  };

  useEffect(() => {
    if (selectedTopicFilter) {
      setFilterOptions([...filterOptions, selectedTopicFilter]);
      setSortOrder(selectedTopicFilter.key);
    }
  }, [selectedTopicFilter]);

  return (
    <div
      className={cx(
        "CommunityPostTab my-6 flex w-screen flex-col p-0 md:w-auto lg:px-2"
      )}>
      <div className="flex justify-around md:mx-auto lg:mx-1">
        {/* Posts */}
        <div
          className={cx({
            "": community && !community.expandPostDirectory,
          })}>
          {/* highlights component */}
          <FeaturedPosts />
          {/* create post component */}
          {Validator.hasValue(user) && (
            <CreatePost
              user={user}
              group={undefined}
              groups={undefined}
              sectionId={undefined}
              addPost={(post: Post) => {
                setPosts([post, ...posts]);
              }}
              addToast={addToast}
              community={community}
              inlineDivComponent={
                <>
                  <div className="PostList mb-3 md:min-w-[640px] w-screen cursor-pointer rounded-xl bg-card p-3 shadow-sm md:w-auto md:min-w-[640px]">
                    <div className="flex items-center justify-between">
                      <div className="flex w-full items-center">
                        <Avatar
                          user={user}
                          noName
                          className="flex-none"
                          size={45}
                          extraInfo={undefined}
                        />
                        <div
                          className="flex w-full flex-grow justify-between rounded-full border border-border bg-background px-5 py-2 font-semibold text-secondary-foreground/80"
                          onClick={clickPostInputBox}>
                          <I18>What's on your mind</I18>,{" "}
                          {user.name.split(" ")[0]}?
                          <UIcon icon="pencil" />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            />
          )}
          <div className="mb-3 px-0.5 flex items-center overflow-x-auto w-full w-screen md:w-auto md:min-w-[640px]">
            {/* sort order */}
            <Filter
              selectedOption={sortOrder}
              setSelectedOption={setSortOrder}
              options={filterOptions}
              removeSelectedCommunityTopicFilter={
                removeSelectedCommunityTopicFilter
              }
            />
            {topicOptions?.length > 0 && !selectedTopicFilter && (
              <span
                className="my-1.5 ml-2.5 flex w-fit min-w-28 items-center rounded-full border border-border bg-accent px-2 py-1 text-xs hover:cursor-pointer"
                onClick={() => {
                  setIsTopicModalOpen(true);
                }}>
                <span className="text-xs font-semibold text-secondary-foreground">
                  Select Topic
                </span>
                <UIcon icon="caret-down" className="ml-1 mt-0.5" />
                <SelectTopicModal
                  active={isTopicModalOpen}
                  setActive={setIsTopicModalOpen}
                  availableTopics={topicOptions}
                  selectedTopics={[]}
                  setSelectedFilteredTopic={setSelectedTopicFilter}
                />
              </span>
            )}
          </div>
          <ListView
            items={posts}
            loading={isLoadingPosts}
            className={cx(
              "PostList flex flex-col w-screen md:w-auto md:min-w-[640px] md:max-w-[640px]",
              {
                "pensil-card my-2": community && !community.expandPostDirectory,
              }
            )}
            renderItem={function (post: Post, index: number): ReactNode {
              return community && community.expandPostDirectory ? (
                <PostCard
                  key={post.id}
                  isCommunityPost={true}
                  post={post}
                  noMargin
                  updatePost={(post: Post) => {
                    // get post index
                    const postIndex = posts.findIndex(
                      (p: Post) => p.id === post.id
                    );
                    const oldPosts = [...posts];
                    oldPosts.splice(postIndex, 1, post);
                    setPosts(oldPosts);
                  }}
                  showFullPost={posts.length === 1}
                  deletePost={(post: Post) => {
                    // get post index
                    const postIndex = posts.findIndex(
                      (p: Post) => p.id === post.id
                    );
                    const oldPosts = [...posts];
                    oldPosts.splice(postIndex, 1);
                    setPosts(oldPosts);
                  }}
                />
              ) : (
                <MinimizedPostCard
                  post={post}
                  user={user}
                  updatePost={(post: Post) => {
                    // get post index
                    const postIndex = posts.findIndex(
                      (p: Post) => p.id === post.id
                    );
                    const oldPosts = [...posts];
                    oldPosts.splice(postIndex, 1, post);
                    setPosts(oldPosts);
                  }}
                  showFullPost={posts.length === 1}
                  deletePost={(post: Post) => {
                    // get post index
                    const postIndex = posts.findIndex(
                      (p: Post) => p.id === post.id
                    );
                    const oldPosts = [...posts];
                    oldPosts.splice(postIndex, 1);
                    setPosts(oldPosts);
                  }}
                  key={post.id}
                  displayLikeCommentIcons={
                    !(
                      post.preferences?.hideLikes &&
                      post.preferences?.hideComment
                    )
                  }
                />
              );
            }}
            placeholder={
              <div className="PostList my-2 rounded bg-card p-2 md:min-w-[640px] md:max-w-[640px]">
                <PostListShimmer length={6} type={PostTileType.compact} />
              </div>
            }
            noItemsElement={
              <div className="no-post my-10 p-2 text-center">
                <div className="my-3 font-bold">
                  <I18>Looks like nothing available here right now.</I18>
                </div>
                <div>
                  <I18>Try exploring other tabs</I18>
                </div>
              </div>
            }
            footerElement={
              <div>
                {/* more post loader */}
                {isLoadingMorePosts && (
                  <div className="PostList my-2 rounded bg-card p-2 md:min-w-[640px] md:max-w-[640px]">
                    <PostListShimmer length={3} type={PostTileType.compact} />
                  </div>
                )}

                {/* No more posts available */}
                {hasMorePost && (
                  <div className="flex h-32 place-content-center items-center text-xs text-secondary-foreground/80">
                    <I18>
                      Looks like you have reached the end of the list, No more
                      post to display
                    </I18>
                  </div>
                )}
              </div>
            }
          />
        </div>

        {/* Widgets */}
        {user && community && (
          <div className="HomeWidget flex flex-grow flex-col space-y-3">
            {widgets && widgets.length > 0 && (
              <>
                {widgets.map((w: Widget, index: number) => {
                  if (
                    !user ||
                    (!w.isVisible &&
                      ["user", undefined].includes(community.myRole))
                  ) {
                    return null;
                  } else if (hasPrimaryPermission && !w.isVisibleToAdmin) {
                    return null;
                  }
                  switch (w.type) {
                    case "custom":
                      return <CustomWidget key={index} widget={w} />;
                    case "community-members":
                      return (
                        <MemberWidget
                          key={index}
                          user={user}
                          community={community}
                          memberPreviewLimit={5}
                        />
                      );
                    case "top-posts":
                      return (
                        <div className="" key={index}>
                          <TrendingPostWidget isCommunityPosts={true} />
                        </div>
                      );
                    case "upcoming-events":
                      return (
                        <div key={index}>
                          <UpcomingEventWidget
                            user={user}
                            community={community}
                            isCommunityEvent={true}
                            group={undefined}
                          />
                        </div>
                      );
                    case "community-leaderboard":
                      return (
                        <LeaderBoardWidget
                          key={index}
                          memberPreviewLimit={4}
                          isCommunityLeaderBoard={true}
                          group={undefined}
                        />
                      );
                    default:
                      return null;
                  }
                })}
              </>
            )}
            {hasPrimaryPermission && (
              <>
                <div
                  onClick={() => {
                    analyticsService.track("click-select-widget", {
                      location: "home",
                    });
                    setIsSelectWidgetVisible(true);
                  }}
                  className="flex w-full cursor-pointer place-content-center gap-1 rounded-lg border border-dashed border-border p-3 text-secondary-foreground">
                  <I18>Select Widgets</I18>
                </div>
                {/* Add/Update customs group widgets pop-up */}

                <RightSideModal
                  width={570}
                  setActive={setIsSelectWidgetVisible}
                  active={isSelectWidgetVisible}>
                  <SelectWidgetModalV2
                    widgets={widgets}
                    setActive={setIsSelectWidgetVisible}
                    onWidgetListUpdate={onWidgetListUpdate}
                  />
                </RightSideModal>

                {/* <SelectWidgetModal
                community={community}
                user={user}
                widgets={widgets}
                forCommunity={true}
                active={isSelectWidgetVisible}
                setActive={setIsSelectWidgetVisible}
                addToast={addToast}
                onWidgetListUpdate={onWidgetListUpdate}
              /> */}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
