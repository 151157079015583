import React, { useEffect, useState } from "react";
import { APP_LAYOUT } from "../../contexts/base-config.context";
import { ToastTypes } from "../../contexts/toastr.context";
import { useAppSelector } from "../../hooks/redux.hook";
import { useAppService } from "../../hooks/use-app-service";
import { useToast } from "../../hooks/use-toast.hook";
import { AppLayoutWrapper } from "../../_components/app-layout-wrapper";
import { Button } from "../../_components/button.component";
import { ErrorDiv } from "../../_components/error.component";
import { MaxWidthWrapper } from "../../_components/max-width-wrapper";
import { CommunityService } from "../../_service";

export const COMMUNITY_THEME_SELECTOR_PAGE_ROUTE = "/settings/selecttheme";

function CommunityThemeSelectorPage() {
  const { community } = useAppSelector((state) => {
    return {
      community: state.community,
    };
  });

  const communityId = community?.id;
  const { analyticsService } = useAppService();

  const [primaryColor, setPrimaryColor] = useState("#0445FF");
  const [onPrimaryColor, setOnPrimaryColor] = useState("#FFFFFF");

  const [darkPrimaryColor, setDarkPrimaryColor] = useState("#3478F6");
  const [darkOnPrimaryColor, setDarkOnPrimaryColor] = useState("#FFFFFF");
  const [theme, setTheme] = useState("light");

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingColors, setisLoadingColors] = useState(false);

  const { addToast } = useToast();

  // fetch user posts and details
  useEffect(() => {
    if (communityId) {
      // get user posts
      setisLoadingColors(true);
      CommunityService.getCommunityThemeData(communityId)
        .then(({ themeData }) => {
          setPrimaryColor(themeData.primaryColor);
          setOnPrimaryColor(themeData.onPrimaryColor);
          setDarkPrimaryColor(themeData.darkPrimaryColor);
          setDarkOnPrimaryColor(themeData.darkOnPrimaryColor);
          setTheme(themeData.backgroundColor === "#141519" ? "dark" : "light");
        })
        .finally(() => {
          setisLoadingColors(false);
        });
    }
  }, [communityId]); // no need to track user

  return (
    <AppLayoutWrapper layout={APP_LAYOUT.TWO_COLUMN}>
      <MaxWidthWrapper className="min-h-screen max-w-screen-md">
        <div className="CommunityThemeSelector Card mx-4 rounded bg-card text-secondary-foreground shadow">
          {/* headers */}
          <div className="flex items-center justify-between border-b border-border py-4 pl-4 pr-2">
            <span className="font-bold text-card-foreground">
              Community Theme
            </span>
          </div>
          <div className="card mt-2 p-5 pt-0">
            <div className="mt-2">
              {/* theme selectors */}
              <div className="my-5">
                <h2 className="font-bold text-card-foreground/85">
                  Select Theme
                </h2>
                <div className="flex py-4">
                  {/* light theme */}
                  <div
                    className={
                      "theme-preview mr-5" +
                      (theme === "light" ? " selected" : "")
                    }
                    onClick={(e) => {
                      setTheme("light");
                    }}>
                    <img
                      src="/assets/vectors/theme/light-theme.svg"
                      alt="Light Theme"
                      className="unselected-icon"
                    />
                    <img
                      src="/assets/vectors/theme/light-theme-selected.svg"
                      alt="Light Theme"
                      className="selected-icon"
                    />
                  </div>
                  {/* dark theme */}
                  <div
                    className={
                      "theme-preview mr-5" +
                      (theme === "dark" ? " selected" : "")
                    }
                    onClick={(e) => {
                      setTheme("dark");
                    }}>
                    <img
                      src="/assets/vectors/theme/dark-theme.svg"
                      alt="Light Theme"
                      className="unselected-icon dark rounded border border-border"
                    />
                    <img
                      src="/assets/vectors/theme/dark-theme-selected.svg"
                      alt="Light Theme"
                      className="selected-icon rounded border border-transparent"
                    />
                  </div>
                </div>
              </div>
              <h2 className="scroll-m-20 text-lg font-normal tracking-tight text-card-foreground/85">
                Light theme colors
              </h2>
              <div className="flex flex-col gap-3 rounded-md border border-border bg-muted/10 p-3">
                <ColorTile
                  label={"Select Brand Color"}
                  subtitle={"Customize your button color to match your brand."}
                  color={primaryColor}
                  onChange={(e) => {
                    setPrimaryColor(e.target.value);
                  }}
                />
                <ColorTile
                  label={"Select button Text Color"}
                  subtitle={
                    "Suggestion: Keep inverted contrast against brand color"
                  }
                  color={onPrimaryColor}
                  onChange={(e) => {
                    setOnPrimaryColor(e.target.value);
                  }}
                />
              </div>

              <h2 className="mt-4 scroll-m-20 text-lg font-normal tracking-tight text-card-foreground/85">
                Dark theme colors
              </h2>
              <div className="flex flex-col gap-2 rounded-md border border-border bg-muted/10 p-3">
                <ColorTile
                  label={"Select Brand Color"}
                  subtitle={
                    "Customize your button color to match your brand in dark theme."
                  }
                  color={darkPrimaryColor}
                  onChange={(e) => {
                    setDarkPrimaryColor(e.target.value);
                  }}
                />
                <ColorTile
                  label={"Select button Text Color"}
                  subtitle={
                    "Suggestion: Keep inverted contrast against brand color in dark theme"
                  }
                  color={darkOnPrimaryColor}
                  onChange={(e) => {
                    setDarkOnPrimaryColor(e.target.value);
                  }}
                />
              </div>
            </div>
            {/* divider */}
            <div className="mb-2 mt-16 border-b border-border" />
            {/* footer buttons */}
            <div className="flex justify-between">
              {/* submit button */}
              <Button
                className="mt-3"
                onClick={() => {
                  // check if form can be submitted
                  setIsLoading(true);

                  let themeData = {
                    primaryColor,
                    onPrimaryColor,
                    darkPrimaryColor,
                    darkOnPrimaryColor,
                    theme,
                  };
                  // call api to create community
                  CommunityService.toggleCommunityThemeData(
                    community.id,
                    themeData
                  )
                    .then((response) => {
                      setIsLoading(false);
                      addToast("Theme Colors updated", "", ToastTypes.success);
                      window.location.reload();
                    })
                    .catch((error) => {
                      console.log(error);
                      setIsLoading(false);
                      if (
                        error &&
                        error.response &&
                        error.response.data.message
                      ) {
                        if (error.response.data.themeData.primaryColor) {
                          setErrorMessage(
                            error.response.data.errors.primaryColor[0]
                          );
                        }
                        if (error.response.data.themeData.onPrimaryColor) {
                          setErrorMessage(
                            error.response.data.errors.onPrimaryColor[0]
                          );
                        }
                        if (error.response.data.errors.address) {
                          setErrorMessage(
                            error.response.data.errors.secondaryColor[0]
                          );
                        }
                      } else {
                        setErrorMessage("Something went wrong!");
                      }
                    });

                  analyticsService.track("theme-updated");
                }}
                isLoading={isLoading}
                label="Save Changes"
              />
            </div>
            <ErrorDiv error={errorMessage} />
          </div>
        </div>
      </MaxWidthWrapper>
    </AppLayoutWrapper>
  );
}

interface ColorTileProps {
  label: string;
  subtitle: string;
  color: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoadingColors?: boolean;
}

function ColorTile({
  label,
  subtitle,
  color,
  onChange,
  isLoadingColors,
}: ColorTileProps) {
  return (
    <div className="flex flex-col gap-2 sm:flex-row">
      <div className="flex-grow">
        <div className="font-bold text-card-foreground/95">{label}</div>
        <div className="text-xs text-card-foreground/70">{subtitle}</div>
      </div>
      <ColorPicker
        color={color}
        isLoadingColors={isLoadingColors}
        onChange={onChange}
      />
    </div>
  );
}

interface ColorPickerProps {
  color: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoadingColors?: boolean;
}
/**
 * Component to update a picture resource
 * @param {*} param0
 * @returns
 */

function ColorPicker({
  color,
  onChange = (e) => {},
  isLoadingColors = false,
}: ColorPickerProps) {
  const colorInputRef = React.useRef<any>(null);

  if (isLoadingColors === true || false) {
    return (
      <div className="flex animate-pulse items-center">
        <div className="mr-4 h-8 w-32 flex-grow rounded border border-border bg-accent px-4 py-2" />
        <div className="h-8 w-8 rounded bg-accent shadow" />
      </div>
    );
  }

  return (
    <div className="flex items-center">
      {/* add pattern for color */}
      <input
        type="text"
        className="mr-4 w-32 flex-grow rounded border border-border bg-accent px-4 py-2"
        value={color}
        onChange={onChange}
      />
      <div
        style={{ backgroundColor: color, width: "36px", height: "36px" }}
        className="rounded shadow"
        onClick={(e) => {
          if (colorInputRef.current) colorInputRef.current.click();
        }}></div>
      <input
        type="color"
        ref={(ref) => {
          colorInputRef.current = ref;
        }}
        className="h-0 w-0"
        style={{ visibility: "hidden" }}
        name="favcolor"
        value={color}
        onChange={onChange}
      />
    </div>
  );
}

export function HexToHSL(hex: string): { h: number; s: number; l: number } {
  if (!hex) {
    console.log("CouldHex Color can't be empty", {
      hex,
    });
    throw new Error("Hex Color can't be empty");
  }
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) {
    console.log("Could not parse Hex Color", {
      hex,
    });
    throw new Error("Could not parse Hex Color");
  }

  const rHex = parseInt(result[1], 16);
  const gHex = parseInt(result[2], 16);
  const bHex = parseInt(result[3], 16);

  const r = rHex / 255;
  const g = gHex / 255;
  const b = bHex / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = (max + min) / 2;
  let s = h;
  let l = h;

  if (max === min) {
    // Achromatic
    return { h: 0, s: 0, l };
  }

  const d = max - min;
  s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
  switch (max) {
    case r:
      h = (g - b) / d + (g < b ? 6 : 0);
      break;
    case g:
      h = (b - r) / d + 2;
      break;
    case b:
      h = (r - g) / d + 4;
      break;
  }
  h /= 6;

  s = s * 100;
  s = Math.round(s);
  l = l * 100;
  l = Math.round(l);
  h = Math.round(360 * h);

  return { h, s, l };
}

export function HSLToHex(hsl: { h: number; s: number; l: number }): string {
  const { h, s, l } = hsl;

  const hDecimal = l / 100;
  const a = (s * Math.min(hDecimal, 1 - hDecimal)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = hDecimal - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);

    // Convert to Hex and prefix with "0" if required
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0");
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export function hexToHsl(hex: string): { h: number; s: number; l: number } {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) {
    console.log("Could not parse Hex Color", {
      hex,
    });
    throw new Error("Could not parse Hex Color");
  }
  // Ensure the hex string is in the correct format
  if (hex.startsWith("#")) {
    hex = hex.slice(1);
  }

  // Parse the hex color string
  const r = parseInt(hex.slice(0, 2), 16) / 255;
  const g = parseInt(hex.slice(2, 4), 16) / 255;
  const b = parseInt(hex.slice(4, 6), 16) / 255;

  // Find the maximum and minimum values among r, g, b
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  // Calculate the lightness
  const l = (max + min) / 2;

  let h = 0;
  let s = 0;

  if (max !== min) {
    const delta = max - min;

    // Calculate the saturation
    s = l > 0.5 ? delta / (2 - max - min) : delta / (max + min);

    // Calculate the hue
    switch (max) {
      case r:
        h = (g - b) / delta + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / delta + 2;
        break;
      case b:
        h = (r - g) / delta + 4;
        break;
    }

    h /= 6;
  }

  // Convert h, s, l to percentages and return
  return {
    h: Math.round(h * 360),
    s: Math.round(s * 100),
    l: Math.round(l * 100),
  };
}

export default CommunityThemeSelectorPage;
